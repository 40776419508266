<template>
  <iframe :id="id" :src="printPath" v-show="false" />
</template>

<script>
export default {
  name: "LiPrint",
  props: {
    html: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    printPath() {
      return "about:blank";
    },
  },
  data() {
    return {
      id: undefined,
    };
  },
  methods: {
    print(html) {
      document.getElementById(this.id).contentDocument.body.innerHTML = html;
      setTimeout(() => {
        document.getElementById(this.id).contentWindow.print();
      }, 500);
    },
  },
  mounted() {
    this.id = this.$utils.createUUID();
  },
};
</script>

/**
 * Vuex
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import app from "./modules/app";
import auth from "./modules/auth";

export default new Vuex.Store({
  modules: { app, auth },
});

/**
 * Cookie for auth
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

import Cookies from "js-cookie";

const AuthTokenKey = "website-element-token";

export function setAuthToken(token) {
  return Cookies.set(AuthTokenKey, token);
}

export function getAuthToken() {
  return Cookies.get(AuthTokenKey);
}

export function removeAuthToken() {
  return Cookies.remove(AuthTokenKey);
}

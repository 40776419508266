import request from "./modules/request";

/**
 * Get dict options
 * @param {string} name - Dict field name
 */
export function dictOptions(name) {
  return request({
    url: "system/dict/options/" + name,
    method: "GET",
  });
}

/**
 * Permission
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

import router from "../router";
import store from "../store";

/* NProgress */
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({ showSpinner: false });

/* Authorization information */
import { getAuthToken, removeAuthToken } from "../cookie/auth";
import { getLoginInfo, getRouter } from "../api/sysUser";

/* Route interceptor - beforeEach */
router.beforeEach((to, from, next) => {
  const LOGIN_PAGE = "/login";
  const HOME_PAGE = "/";
  const AuthToken = getAuthToken();
  NProgress.start();
  /* If no AuthToken exists, the LoginInfo is cleared directly */
  if (!AuthToken) {
    store.dispatch("auth/clearLoginInfo");
  }
  if (store.state.auth.loginInfo) {
    /* LoginInfo exists */
    if (to.path === LOGIN_PAGE) {
      next(HOME_PAGE);
      NProgress.done();
    } else {
      next();
    }
  } else {
    /* LoginInfo does not exist */
    /* If an AuthToken exists, attempt to obtain user information */
    if (AuthToken) {
      /* Update loginInfo */
      getLoginInfo()
        .then((res) => {
          store.dispatch("auth/updateLoginInfo", res.data).then(() => {
            /* Update routerMap */
            getRouter().then((resMap) => {
              store
                .dispatch("auth/updateRouterMap", resMap.data.routerMap)
                .then(() => {
                  if (to.path === LOGIN_PAGE) {
                    next(HOME_PAGE);
                    NProgress.done();
                  } else {
                    next();
                  }
                });
            });
          });
        })
        .catch(() => {
          removeAuthToken();
          if (to.path === LOGIN_PAGE) {
            next();
          } else {
            next(LOGIN_PAGE);
            NProgress.done();
          }
        });
    } else {
      /* AuthToken does not exist */
      if (to.path === LOGIN_PAGE) {
        next();
      } else {
        next(LOGIN_PAGE);
        NProgress.done();
      }
    }
  }
  next();
});
/* Route interceptor - afterEach  */
router.afterEach(() => {
  NProgress.done();
});

import request from "./modules/request";

/**
 * System user api
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

/**
 * 验证码
 */
export function captchaImage() {
  return request({
    url: "/captchaImage",
    method: "GET",
  });
}

/**
 * 登录
 * @param {string} formData.username - 登录名称
 * @param {string} formData.password - 登录密码
 * @param {string} formData.key - 验证码key
 * @param {string} formData.code - 验证码
 */
export function login(formData) {
  return request({
    url: "/login",
    method: "POST",
    data: formData,
  });
}

/**
 * 登录信息
 */
export function getLoginInfo() {
  return request({
    url: "/getLoginInfo",
    method: "GET",
  });
}

/* Mock router data */
import routerMap from "@/mock/routerMap";

/**
 * 权限路由
 */
export function getRouter() {
  // return request({
  //     url: "/getRouter",
  //     method: "GET"
  // })

  return new Promise((resolve, reject) => {
    resolve(routerMap);
    reject();
  });
}

/**
 * 登出
 */
export function logout() {
  return request({
    url: "/logout",
    method: "DELETE",
  });
}

/**
 * Resource
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */
import Vue from "vue";

/** Lib resource */
/* Element-UI */
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

/* Lib CSS */
import "../assets/lib/index.less";
/* Lib JavaScript */

/** System status resource */
/* Project CSS */
import "../assets/static/index.less";
/* Project JavaScript */
import "../assets/static/index.js";

import "@wangeditor/editor/dist/css/style.css";

import "print-js/dist/print.css";

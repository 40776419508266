<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <el-breadcrumb-item
      v-show="!item.meta.hiddenBreadcrumb"
      v-for="item in $route.matched"
      :key="item.name"
      :to="
        item.path !== $route.path && item.redirect !== $route.path
          ? { path: item.path }
          : null
      "
      >{{ item.meta.title }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "LayoutBreadcrumb",
};
</script>
<style scoped lang="less">
.breadcrumb {
  line-height: 50px;
}
</style>

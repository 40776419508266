/**
 * @author Zhong Li
 * @description Directive
 * @since 1.0.0
 * @version 1.0.0
 */
import Vue from "vue";
import store from "@/store";
Vue.directive("hasAccess", {
  inserted: function (el, bind) {
    const { value } = bind;
    if (store.state.auth.loginInfo.accessList.indexOf(value) < 0) {
      el.remove();
    }
  },
});

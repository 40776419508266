import request from "./modules/request";

/**
 * System file api
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

export function uploadStatisFile(file) {
  let formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/system/file/uploadStaticFile",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

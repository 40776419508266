<!-- eslint-disable vue/no-dupe-keys -->
<template>
  <div class="li-page">
    <slot v-if="!component"></slot>
    <component
      v-if="component"
      :is="component"
      :params="params"
      :query="query"
      @subpage-submit="handleSubmit"
      @subpage-cancel="handleCancel"
    />
  </div>
</template>

<script>
export default {
  name: "LiPage",
  props: {
    subpage: {
      type: String,
      default: undefined,
    },
    // 传给弹窗加载页面或组件的参数
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      component: undefined, // 加载
      query: {},
    };
  },
  watch: {
    subpage() {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.subpage) {
        const [component, query] = this.subpage.split("?");
        const params = {};
        if (query) {
          query.split("&").forEach((item) => {
            params[item.split("=")[0]] = item.split("=")[1];
          });
        }
        this.query = params;
        this.component = this.$utils.lazyLoadView(component);
      } else {
        this.component = undefined;
      }
    },
    handleSubmit(data) {
      this.$emit("subpage-submit", data);
    },
    handleCancel(data) {
      this.$emit("subpage-cancel", data);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

/**
 * Request Module
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */
import axios from "axios";
import { Message } from "element-ui";
import { getAuthToken, removeAuthToken } from "@/cookie/auth";
import store from "@/store";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

/* Http status constant */
const HttpStatus = {
  OK: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  SERVERERROR: 500,
};

/* Create an Axios instance */
const request = axios.create({
  /* BaseUrl */
  baseURL: process.env.VUE_APP_API_BASE_URL,
  /* Timeout, unit of milliseconds */
  timeout: 30000,
});

/* Request interceptor */
request.interceptors.request.use(
  (config) => {
    /* AuthToken */
    if (getAuthToken()) {
      config.headers.Authorization = getAuthToken();
    }
    return config;
  },
  () => {
    Message.error("Initiating request exception");
  }
);

/* Response interceptor */
request.interceptors.response.use(
  (res) => {
    let code = res.data.code;
    if (!code || code === 0) {
      return res.data;
    } else {
      Message.error(res.data.message);
      return Promise.reject(res.data);
    }
  },
  (error) => {
    let status = error.response.status;
    let statusText = error.response.data.message || error.response.statusText;
    if (status === HttpStatus.UNAUTHORIZED) {
      store.dispatch("auth/clearLoginInfo");
      removeAuthToken();
      Message.error(statusText);
    } else if (status === HttpStatus.FORBIDDEN) {
      Message.error(statusText);
      return Promise.reject("Forbidden：" + statusText);
    } else if (status === HttpStatus.SERVERERROR) {
      Message.error(statusText);
      return Promise.reject("Error：" + statusText);
    } else {
      Message.error(statusText);
      return Promise.reject(statusText);
    }
  }
);

export default request;

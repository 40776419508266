/**
 * Store for auth
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

import router from "@/router";
import Layout from "@/layout";

const state = {
  loginInfo: undefined,
  routerMap: [],
};

const mutations = {
  updateLoginInfo: (state, loginInfo) => {
    state.loginInfo = loginInfo;
  },

  clearLoginInfo: (state) => {
    state.loginInfo = undefined;
  },

  updateRouterMap: (state, routerMap) => {
    state.routerMap = routerMap;
  },
};

const actions = {
  updateLoginInfo: ({ commit }, loginInfo) => {
    commit("updateLoginInfo", loginInfo);
  },

  clearLoginInfo: ({ commit }) => {
    commit("clearLoginInfo");
  },

  updateRouterMap: ({ commit }, routerMap) => {
    if (parseRouterMap(routerMap)) {
      routerMap.push({
        path: "*",
        redirect: "/404",
        meta: { hidden: true },
      });
      router.addRoutes(routerMap);
      commit("updateRouterMap", routerMap);
    }
  },
};

function parseRouterMap(routerMap) {
  const loadView = (view) => {
    return (resolve) => require([`@/views/${view}`], resolve);
  };

  if (!Array.isArray(routerMap)) {
    return false;
  }
  routerMap.forEach((route) => {
    if (route.component === "Layout") {
      route.component = Layout;
    } else {
      route.component = loadView(route.component);
    }
    if (route.children && route.children.length > 0) {
      parseRouterMap(route.children);
    }
  });
  return true;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

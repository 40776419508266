<template>
  <div class="user-info">
    <p class="nickname" v-if="loginInfo">欢迎登录：河源市保安服务有限公司</p>
    <el-dropdown trigger="click">
      <div class="el-dropdown-link">
        <img class="img-avatar" :src="avatar" />
        <i class="el-icon-caret-bottom icon-bottom"></i>
      </div>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { logout } from "@/api/sysUser";
import { removeAuthToken } from "@/cookie/auth";
import { mapState } from "vuex";

export default {
  name: "UserInfo",
  data() {
    return {
      avatar: require("@/assets/static/img/defaultAvatar.png"),
    };
  },
  computed: {
    ...mapState({
      loginInfo: (state) => state.auth.loginInfo,
    }),
  },
  methods: {
    logout() {
      this.$confirm("是否登出？", {
        type: "warning",
      })
        .then(() => {
          logout().then(() => {
            removeAuthToken();
            this.$store.dispatch("auth/clearLoginInfo");
            this.$router.push("/login");
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.user-info {
  line-height: 50px;
  height: 50px;
  cursor: pointer;
  padding: 0 15px;
  outline: none;

  .nickname {
    display: inline-block;
    padding: 0 15px;
  }

  .el-dropdown-link {
    position: relative;
    outline: none;

    .img-avatar {
      width: 37px;
      height: 37px;
      border-radius: 5px;
      vertical-align: middle;
    }

    .icon-bottom {
      position: absolute;
      bottom: 3px;
    }
  }
}
</style>

<template>
  <el-select
    v-model="selectedValue"
    :placeholder="placeholder"
    @change="handleChange"
    :clearable="!disabled"
  >
    <el-option
      v-for="dict in options"
      :key="dict.value"
      :label="dict.label"
      :value="dict.value"
      :disabled="disabled"
    />
  </el-select>
</template>

<script>
import { dictOptions } from "@/api/sysDict";

export default {
  name: "LiDictSelect",
  props: {
    dict: {
      type: String,
      default: undefined,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selectedValue: undefined,
    };
  },
  watch: {
    value(v) {
      this.selectedValue = v;
    },
    selectedValue(v) {
      this.$emit("input", v);
    },
  },
  methods: {
    listDict() {
      dictOptions(this.dict).then((res) => {
        this.options = res.data;
      });
    },
    handleChange(value) {
      const found = this.options.find((element) => element.dictValue === value);
      setTimeout(() => {
        this.$emit("change", value, found);
      }, 200);
    },
  },
  mounted() {
    this.selectedValue = this.value;
    this.listDict();
  },
};
</script>

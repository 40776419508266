/**
 * Main
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/* Modules */
import "./install/resource";
import "./install/permission";
import "./install/prototype";
import "./install/components";
import "./install/mixin";
import "./install/directive";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="comm-liEditor" v-if="ready">
    <Toolbar
      class="box-toolbar"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      class="box-editor"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { uploadStatisFile } from "@/api/sysFile";

export default {
  name: "LiEditor",
  components: { Editor, Toolbar },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "请输入内容...",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(v) {
      this.html = v;
    },
    html(v) {
      this.$emit("input", v);
    },
  },
  data() {
    return {
      ready: false,
      editor: null,
      html: undefined,
      toolbarConfig: {},
      editorConfig: {
        placeholder: undefined,
        readOnly: undefined,
        MENU_CONF: {
          uploadImage: {
            // 自定义上传
            async customUpload(file, insertFn) {
              uploadStatisFile(file).then((res) => {
                insertFn(res.data);
              });
            },
          },
          uploadVideo: {
            // 自定义上传
            async customUpload() {
              uploadStatisFile(file).then((res) => {
                insertFn(res.data);
              });
            },
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {
    this.editorConfig = {
      ...this.editorConfig,
      placeholder: this.placeholder,
      readOnly: this.disabled,
    };
    this.ready = true;
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
    this.ready = false;
  },
};
</script>
<style lang="less" scoped>
.comm-liEditor {
  border-bottom: 1px solid #ccc;
  .box-toolbar {
    border-bottom: 1px solid #ccc;
  }
  .box-editor {
    height: 500px;
  }
}
</style>

/**
 * Mock router
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */
const routerMap = {
  code: 200,
  msg: "success",
  data: {
    routerMap: [
      {
        path: "/",
        redirect: "/dashboard",
        component: "Layout",
        meta: {
          title: "首页",
          icon: "fa fa-dashboard",
          hiddenBreadcrumb: true,
        },
        children: [
          {
            path: "/dashboard",
            component: "application/dashboard/",
            meta: { title: "首页" },
          },
        ],
      },
      {
        path: "/personnel",
        redirect: "/personnel/list",
        component: "Layout",
        meta: { title: "人员管理", icon: "fa fa-address-card", group: true },
        children: [
          {
            path: "/personnel/list",
            component: "application/personnel/list.vue",
            meta: { title: "保安人员" },
          },
          {
            path: "/personnel/code",
            component: "application/personnel/code.vue",
            meta: { title: "员工邀请码" },
          },
          {
            path: "/personnel/candidate/list/",
            component: "application/personnel/listCandidate.vue",
            meta: { title: "应聘申请" },
          },
        ],
      },
      {
        path: "/news",
        redirect: "/news/list",
        component: "Layout",
        meta: { title: "新闻中心", icon: "fa fa-newspaper-o", group: true },
        children: [
          {
            path: "/news/list",
            component: "application/news/list.vue",
            meta: { title: "新闻列表" },
          },
        ],
      },
      {
        path: "/complaint",
        redirect: "/complaint/internal",
        component: "Layout",
        meta: { title: "投诉管理", icon: "fa fa-envelope", group: true },
        children: [
          {
            path: "/complaint/external",
            component: "application/complaint/external.vue",
            meta: { title: "外部投诉" },
          },
          {
            path: "/complaint/internal",
            component: "application/complaint/internal.vue",
            meta: { title: "内部投诉" },
          },
        ],
      },
    ],
  },
};

export default routerMap;

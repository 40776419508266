import Vue from "vue";

/** Common Component */
import LiModal from "../components/LiModal/index.js";
import LiDictSelect from "../components/LiDictSelect/index.js";
import LiDictTag from "../components/LiDictTag/index.js";
import LiEditor from "../components/LiEditor/index.js";
import LiPage from "../components/LiPage/index.js";
import LiPrint from "../components/LiPrint/index.js";

const components = [
  LiModal,
  LiDictSelect,
  LiDictTag,
  LiEditor,
  LiPage,
  LiPrint,
];

/** 加载自定义组件 */
components.forEach((component) => {
  Vue.component(component.name, component);
});

/**
 * Vue router
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

/**
 * meta: {
 *  title: Title name, string.
 *  icon: Icon class name, string.
 *  hidden: SideBar hidden this , boolean.
 *  hiddenBreadcrumb: NavBar breadcrumb hidden this, boolean.
 *  hiddenTagView: TagView hidden this, boolean.
 *  group: Is menu group, boolean.
 * }
 */

/* Public routers */
const routes = [
  {
    path: "/login",
    component: () => import("@/views/framework/login/"),
    meta: { hidden: true },
  },
  {
    path: "/404",
    component: () => import("@/views/framework/error/404/"),
    meta: { hidden: true },
  },
  {
    path: "/401",
    component: () => import("@/views/framework/error/401/"),
    meta: { hidden: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

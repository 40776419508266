/**
 * Cookie for app
 *
 * @author ZhongLi
 * @see <a href="https://website-element.com">WebsiteElement</a>
 */

import Cookies from "js-cookie";

const sideBarOpenedStatusKey = "sideBarOpened";

export function setSideBarOpenStatus(status) {
  return Cookies.set(sideBarOpenedStatusKey, status);
}

export function getSideBarOpenStatus() {
  return Cookies.get(sideBarOpenedStatusKey);
}
